export const state = {
  navbar_title: 'Inicio',
  navbar_picture: '',
};


export const mutations = {
  CHANGE_NAVBAR_TITLE(state, title) {
    state.navbar_title = title;
  },
  CHANGE_NAVBAR_PICTURE(state,picture){
    state.navbar_picture = picture;
  }
};

export const actions = {
  changeNavbarTitle({ commit },  title) {
    commit('CHANGE_NAVBAR_TITLE', title);
  },
  changeNavbarPicture({ commit }, picture){
    commit("CHANGE_NAVBAR_PICTURE", picture);
  }
};

export const getters = {
  getNavbarTitle: (state) => () => { return state.navbar_title },
  getNavbarPicture: (state)=> () => { return state.navbar_picture}
};