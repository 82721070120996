import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../../app.config";
import store from "../state/store"


const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
  
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }
    document.title = routeTo.meta.title + ' | ' + appConfig.title;
    // If we reach this point, continue resolving the route.
    next();
});

function nextFactory(context, middleware, index) {
  const nextMiddleware = middleware[index];
  
  if (!nextMiddleware){
    return context.next;
  }
  const subsequentMiddleware = nextFactory(context, middleware, index + 1);
  return nextMiddleware({ ...context, next: subsequentMiddleware })
}

router.beforeEach((to, from, next) => {
  // console.log(store.getters['subdom/subdomData']())
  let subdom = window.location.host.split('.')[0]
  let dom = 'arbitt'
  if(subdom !== dom) {
    store.dispatch('subdom/setSubdomData', subdom)
  } else {
    store.dispatch('subdom/setSubdomData', false)
  }
  if(!to.meta.middleware){
    return next();
  }

  const middleware = Array.isArray(to.meta.middleware)
    ? to.meta.middleware
    : [to.meta.middleware];
  const context = {
    to,
    from,
    next,
    router,
  };

  return middleware[0]({ ...context, next: nextFactory(context, middleware, 1) });

});

export default router;